import { useLoggedUser } from "../store/useLoggedUser"
import { getRoomsList } from "./getRoomsList"
import { fetchPost, fetchGet } from "./restHelpers"


async function fetchLoginToken(login:string, password:string){
    //console.log('fetchLogin ',login,password)
    const res = await fetchPost('/common/auth/sign-in',{
        "phonePrefix":"+48",
        "emailOrPhone": login,
        "password": password,
        "role": "customer_service"
    })

    return res?.token ?? ''
}






export {fetchLoginToken,getRoomsList}