import { create } from 'zustand'
import { subscribeToRoom } from '../api/firebaseHelpers'
import { useRooms } from './useRooms'

interface iuseRoomMessages {
    roomId: string | undefined,
    messages:ichatMsg[],
    loading:boolean,
    unsubscribe:()=>void,
    subscribe:(roomId:string | undefined,messagesLimit:number)=>void,
}
 
const useRoomMessages = create<iuseRoomMessages>(
    (set,get) => ({
         roomId:undefined,
         messages:[],
         loading:false,
         unsubscribe:()=>{},
         subscribe:(roomId:string | undefined,messagesLimit:number)=>{
            get().unsubscribe()
            set({loading:true,messages:[]})
            if(!roomId) return;
            const unsubscribe = subscribeToRoom(roomId,100,snapshot => {
                //console.log('snapshot in room ',room.roomId,' triggered')
                const newmessages:ichatMsg[] = []
                snapshot.forEach(doc => newmessages.push(convertChatMsgFromFirestore(doc.id,doc.data() as ichatMsgFromFirestore)))
                set({loading:false,messages:newmessages})
            })
            set({unsubscribe})
         },
     })
 )


function convertChatMsgFromFirestore(id:string,{content:{data:{accountId,message}},date}:ichatMsgFromFirestore):ichatMsg {
    //console.log(id,message)

    //console.log({type:message.type,uri:message.body.uri})

    return {
        id,
        accountId,
        type: message.type,
        fileExtension: message.body.fileExtension,
        fileName:message.body.fileName,
        alt:message.body.alt,
        thumbUri:message.body.thumbUri,
        uri:message.body.uri,
        text:message.body.text,
        date
    }
}


interface ichatMsg {
    id:string,
    accountId: string,
    type: string,
    fileExtension?:string,
    fileName?:string,
    alt?:string,
    thumbUri?:string,
    uri?:string,
    text?:string
    date:{seconds:number,nanoseconds:number}
}

interface ichatMsgFromFirestore {
    content: {
        data: {
            roomId:string,
            accountId: string,
            isDeleted: boolean,
            message: {
                type:string, //image, text
                body: {
                    fileExtension?:string,
                    fileName?:string,
                    alt?:string,
                    thumbUri?:string,
                    uri?:string,
                    text?:string
                }
            }

        },
        type: string //chat-message
    },
    date:{seconds:number,nanoseconds:number} //timestamp
}

 export {useRoomMessages}
 export type {ichatMsgFromFirestore, ichatMsg}