import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { ElementRef, useEffect, useMemo, useRef, useState } from "react"
import { AccessDenied, ServerError } from "./api/apiErrors";
import { fetchPost } from "./api/restHelpers";
import { AppHeader } from "./components/AppHeader"


async function postInvitation(data:{id:string, patientPhoneNumber:string}){
    const endpoint = "/doctor/public-profile/sm-one-invitation"
    const result = await fetchPost(endpoint,data)
    return result;
}


function SmOneApp(){
    //const { id: publicId, phone: patientPhoneNumber } = data;
    
    const [isSending, setIsSending] = useState(false)
    const formRef = useRef<ElementRef<"form">>(null)


    const handleSubmit = async (ev:any) => {
        const formEls = formRef.current?.elements
        const formdata = {
            "id": (formEls?.namedItem("id") as HTMLInputElement).value,
            "patientPhoneNumber": (formEls?.namedItem("phone") as HTMLInputElement).value,
        }
        console.log('form button clicked',formdata)
        if(!formdata.id || !formdata.patientPhoneNumber) return;
        ev.preventDefault()

        setIsSending(true)

        const result = await postInvitation(formdata)
        console.log(result)
        setIsSending(false)

        if(result?.status!==201){
            const msg = result?.message
            const code = result?.errorCode
            if(code == 'DOCTOR_DOES_NOT_HAVE_SM_PLAN'){
                alert('Doctor does not have Sm-one plan enabled')
                return;
            }
            if(!msg){
                alert('Error while sending an invitation')
                return;
            }
            alert('Error: ' +  msg.toString())
            return;
        }
        alert('Success')
        formRef.current?.reset()
    }


    return <div className="SendInvitationApp">
            <AppHeader/>
            <div className='AppContent'>
            <h2>Send an invitation to SM-One plan</h2>
            <form ref={formRef}>
                <div className="row"><label htmlFor="id">Doctor's public ID</label> <input type="text" name="id" required></input></div>
                <div className="row"><label htmlFor="phone">Patient's phone number</label> <input type="text" name="phone" required></input></div>
                <div className="row">
                    <button disabled={isSending} onClick={handleSubmit}>{isSending ? "sending.." : "Invite patient to SM-One"}</button>
                </div>

            </form>
            </div>
            </div>
}


export {SmOneApp}