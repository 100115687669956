import { useRef, useEffect } from "react"
import { createPortal } from "react-dom"
import { useLoggedUser } from "../store/useLoggedUser"


function AuthorizedImage({src,onClick}:{src:string,onClick?:()=>void}){
    const ref = useAuthorizedImage(src)
    return <img ref={ref} onClick={onClick} />
  }
  
  function FullscreenAuthorizedImage({src,onClick}:{src:string | null,onClick?:()=>void}){
    return src == null ? null : createPortal(
      <div className='Fullscreen' onClick={onClick}>
        <AuthorizedImage src={src}></AuthorizedImage>
      </div>
      ,document.body
    )
  }
  
  function useAuthorizedImage(src:string){
    const ref = useRef<HTMLImageElement | null>(null)
    useEffect(()=>{
      fetchAuthorizedImage(src).then(blob=>ref.current !== null && (ref.current.src = URL.createObjectURL(blob)) )
    },[])
    return ref
  }
  


  async function fetchAuthorizedImage(src:string):Promise<Blob>{
    const {jwt} = useLoggedUser.getState()
    const cached = imageCache.get(src)
    if(cached){
      //console.log('found cached ',src,cached.blob.size,cached.blob.type)
      return cached.blob
    }
    const ImageRequest = new Request(
      src, {
        headers: {Authorization: "Bearer "+jwt},
        mode: "cors",
        credentials: "include",
      }
    )
    return fetch(ImageRequest).then(resp=>{
      
      return resp.blob().then(blob => {
        imageCache.add(src,blob)
        //console.log('adding to cache',src, blob.size, blob.type,'cache size',imageCache.size)
        return blob;
      })

    })
  }


  type CachedImage = {src:string, blob:Blob}
  class ImageCache {
    maxSize: number
    cacheArray: CachedImage[]

    get size():number{
      return this.cacheArray.length
    }

    get keys():string[]{
      return this.cacheArray.map(i=>i.src)
    }

    constructor(size:number){
      this.maxSize = size;
      this.cacheArray = []
    }

    get(src:string):CachedImage | undefined{
      return this.cacheArray.find(i=>i.src == src)
    }

    add(src:string, blob:Blob):CachedImage{
      const found = this.get(src)
      if(found !== undefined) return found;
      const imgobj = {src,blob}
      this.cacheArray.push(imgobj)
      if(this.size > this.maxSize){
        this.cacheArray.shift()
      }
      return imgobj;
    }
  }

  const imageCache = new ImageCache(20)
  //@ts-ignore
  window.imageCache = imageCache;

  export {AuthorizedImage,FullscreenAuthorizedImage}