
import './App.css';
import React, { useEffect, useRef, useState } from 'react';

import { useLoggedUser } from './store/useLoggedUser';
import { useRooms } from './store/useRooms';
import { ichatMsg, useRoomMessages } from './store/useRoomMessages';
import { iRoom } from './api/getRoomsList';
import { AppHeader } from './components/AppHeader';
import { AuthorizedImage, FullscreenAuthorizedImage } from './components/AuthorizedImage';


const cnames = (obj:any) => ({ className : Object.entries(obj).flatMap( ([k,v]) => v ? [k] : [] ).join(' ') } )

function ChatApp() {

  
  const rooms = useRooms(s=>s.rooms)
  const setCurrentRoom = useRooms(s=>s.setCurrentRoom)
  const currentRoom = useRooms(s=>s.getCurrentRoom())
  const currentRoomId = currentRoom?.roomId
  const sendMessage = useRooms(s=>s.sendMessage)

  const subscribeToRoomMessages = useRoomMessages(s=>s.subscribe)

  const textRef = useRef<HTMLTextAreaElement | null>(null)
  const handleSendMsg = () => {
    console.log('click send msg')
    const msg = textRef.current?.value ?? ''
    if(msg === '' || !currentRoomId)return;
    sendMessage(currentRoomId,msg) 
    if(textRef.current!==null){
      textRef.current.value = ''
      textRef.current.focus()
    }
  }

  const changeRoom = (room:iRoom) => {
    console.log('changeRoom to ',room )
    subscribeToRoomMessages(room.roomId,100)
    setCurrentRoom(room.roomId)
  }

  const renderUserElement = (room : iRoom) => 
    <div {...cnames({UserElement:true,Unread:room.unread,Selected:room.roomId == currentRoomId,NewUser:room.newuser})} key={room.roomId} onClick={()=>changeRoom(room)}>
      {room.participant.name + ' ' + room.participant.surname + ' ' + room.participant.role_id}
      <div className='description'>{room.unread ? 'U ' : ' '}{room.lastMessage.createdDate != null ? (new Date(room.lastMessage.createdDate)).toLocaleString():'null'}</div>
    </div>


  return <div className="App">
      <AppHeader/>
      <div className='AppContent'>
        <div className='RoomsListContainer'>
          {
            !rooms.length ? "loading.." : null
          }
          {
            rooms.filter(room => room.unread).map(renderUserElement)
          }
          {
            rooms.filter(room => !room.unread).map(renderUserElement)
          }

        </div>
        <div className='ChatContainer'>
          <ChatHeader room={currentRoom}></ChatHeader>
          <ChatPanel></ChatPanel>
          <div className='ChatFooter'>
            <textarea ref={textRef}></textarea>
            <a className="BigButton" onClick={handleSendMsg}>Wyślij</a>
          </div>
        </div>
      </div>
      <div className='AppFooter'>
          D1 Customer Service Chat
      </div>
    </div>
}


function ChatHeader({room}:{room:iRoom | undefined}){
  return !room 
    ? <div className='ChatHeader'></div>
    : <div className='ChatHeader'>
        <div><b>{room.participant.name} {room.participant.surname} {room.participant.role_id}</b></div>
        <div>tel: {room?.participant.phone}, email: <a href={"mailto:"+room?.participant.email}>{room?.participant.email}</a>, account id: {room?.participant.account_id}</div>
      </div>
}

function ChatPanel(){
  const sending = useRooms(s=>s.sending)
  const loading = useRoomMessages(s=>s.loading)
  const messages = useRoomMessages(s=>s.messages)
  const account_id = useLoggedUser(s=>s.account_id)
  const [fullscreenSrc,setFullscreen] = useState<string | null>(null)

  const renderMsg = (msg : ichatMsg,i:number, arr:ichatMsg[]) => {

    const date = new Date(msg.date.seconds * 1000);
    const nextdate = i < arr.length -1 ? new Date(arr[i+1].date.seconds * 1000) : date;
    const datefiff = Math.floor(Math.abs(date.getTime() - nextdate.getTime())/1000)
    const datestr = `${date.toLocaleDateString()},${date.getHours()}:${("0"+date.getMinutes()).slice(-2)}`

    return <React.Fragment key={msg.id}>
      <div {...cnames({ChatBubble:true,fromMe:msg.accountId == account_id})} >
        {msg.text} 
        {msg.thumbUri ? 
          <AuthorizedImage src={msg.thumbUri} onClick={()=>setFullscreen(msg.uri ?? null)} /> 
        : null}
      </div>
      {
        i == arr.length-1 || datefiff > 30*60 ? <div className='ChatBubbleDate'>{datestr}</div> : null
      }
    </React.Fragment> 
  }

  return <div className='ChatPanel'>
    {
      sending ? <div className='ChatBubble fromMe'>sending..</div> : null
    }
    {
      loading 
      ? "Loading.." 
      : messages.map(renderMsg)
    }
    <FullscreenAuthorizedImage src={fullscreenSrc} onClick={()=>setFullscreen(null)} />
  </div>
}



export default ChatApp;
