
import { DocumentData, QuerySnapshot } from 'firebase/firestore'
import { create } from 'zustand'
import { subscribeToNewMessages } from '../api/firebaseHelpers'
import { getRoomsList, iRoom } from '../api/getRoomsList'
import { markRoomAsRead } from '../api/markRoomAsRead'
import { ichatMsgFromFirestore } from './useRoomMessages'
import { useLoggedUser } from './useLoggedUser'
import { sendMessage } from '../api/sendMessage'
import { subscribeWithSelector } from 'zustand/middleware'

interface iuseRooms {
    rooms:iRoom[],
    currentRoomId:string | undefined,
    index:{[key:string]:number},
    sending:boolean,
    getCurrentRoom: () => iRoom | undefined,
    setCurrentRoom: (currentRoomId: string | undefined) => void,
    load: () => Promise<void>,
    markUnread: (roomId:string) => Promise<void>,
    markAsRead: (roomId:string) => Promise<void>,
    sendMessage: (roomId:string,txt:string) => Promise<void>,
    findById: (roomId:string) => undefined | iRoom,
    updateById: (roomId:string,data:Partial<iRoom>) => void,
    pushToTop:(roomId:string,roomData?:Partial<iRoom>) => void
}


const buildRoomsIndex = (rooms:iRoom[]) => rooms.reduce<{[key:string]:number}>( (index,room,i) => ({...index,[room.roomId]:i}),{})

const useRooms = create<iuseRooms>()(subscribeWithSelector( 
   (set,get) => ({
        rooms: [],
        currentRoomId: undefined,
        index:{},
        sending:false,
        getCurrentRoom: () => {
            const {currentRoomId, findById} = get()
            return currentRoomId ? findById(currentRoomId) : undefined 
        },
        setCurrentRoom: (currentRoomId: string | undefined) => set({currentRoomId}),
        load: async () => {
            const rooms = await getRoomsList()
            //console.log('load unread count:',rooms.filter(r=>r.unread).length )
            set({rooms,index:buildRoomsIndex(rooms)})
        },
        markUnread: async (roomId:string) => {
            //console.log('markUnread',roomId)
            get().updateById(roomId,{unread:true})
        },
        markAsRead: async (roomId:string) => {
            const {updateById,findById} = get()
            const found = findById(roomId)
            //console.log('should mark as read? ',found)
            if(!found || found.unread == false)return;
            //console.log('markAsRead',roomId)
            updateById(roomId,{unread:false,readAt:(new Date()).toJSON()})
            markRoomAsRead(roomId)
        },
        sendMessage: async (roomId:string,txt:string) => {
            if(!roomId)return;
            set({sending:true})
            sendMessage(roomId,txt)
                .then(res => {
                    const {updateById,pushToTop} = get()
                    const {account_id} = useLoggedUser.getState()
                    set({sending:false})
                    //todo: powinnismy obsluzyc sytuacje gdy send sie nie powiodl
                    //gdy send sie powiodl res.date zawiera date wyslania wiadomosci przyjeta przez serwer, w formacie JSON
                    const sendDate = res?.date ?? (new Date()).toJSON()
                    //updateById(roomId,{unread:false,readAt:currentDate,lastMessage:{userId:account_id,createdDate:currentDate}})
                    pushToTop(roomId,{unread:false,readAt:sendDate,lastMessage:{userId:account_id,createdDate:sendDate}})
                })
                .catch(err => set({sending:false}))
        },
        findById: (roomId:string) => get().index[roomId] !=undefined ? get().rooms[get().index[roomId]] : undefined, //get().rooms.find(room => room.roomId == roomId),
        updateById: (roomId:string,data:Partial<iRoom>) => {
            //console.log('updateById',roomId);
            set(state => ({rooms:state.rooms.map(room => room.roomId == roomId ? Object.assign({},room,data): room)}));
            //console.log('updateById unread count:',get().rooms.filter(r=>r.unread).length)
        },
        pushToTop: (roomId:string,roomData?:Partial<iRoom>) => {
            const {index,rooms} = get()
            const found = index[roomId]
            if(found === undefined)return;
            
            //console.log('pushToTop unread count:',rooms.filter(r=>r.unread).length )

            const newrooms = [Object.assign(rooms[found],roomData),...rooms.slice(0,found),...rooms.slice(found+1)]

            //console.log('pushToTop new unread count:',newrooms.filter(r=>r.unread).length )
            //console.log('push to top, reindex')
            set({rooms:newrooms,index:buildRoomsIndex(newrooms)})
        }
    })
))

let unsubscribeNewMessages = () => {}

useLoggedUser.subscribe(state => state.logged, (logged,prevlogged) => {
    if(logged === prevlogged) return;
    const {load} = useRooms.getState()
    if(logged){
        load()
        unsubscribeNewMessages = subscribeToNewMessages(50, snapshot => {
            if(!syncRoomsWithSnapshot(snapshot)){
                console.log('not every room was synced, loading rooms')
                load()
            }
        })
    } else {
        unsubscribeNewMessages()
    }
})



//ta funkcja sprawdza kazda przychodzaca wiadomosc i jezeli jest nowa to oznacza pokoj jako "nieprzeczytany"
//jezeli nie znajduje odpowiedniego pokoju to zwraca false, wtedy funkcja nadrzedna odswierza liste wszystkich pokoi
const syncRoomsWithSnapshot =  (snapshot:QuerySnapshot<DocumentData>) => {
    let allRoomsSynced = true;
    const {findById,markUnread} = useRooms.getState()
    const {uid,account_id} = useLoggedUser.getState()
    
    snapshot.forEach(doc => {
        
        if(!allRoomsSynced)return;

        const {date, content:{type:content_type,data:{roomId,message,accountId:fromAccountId}}} = doc.data() as ichatMsgFromFirestore
        if(fromAccountId == uid || fromAccountId == account_id || content_type != 'chat-message')return;

        const room = findById(roomId)
        //console.log('got message from ',room?.participant.name,room?.participant.surname,message)
        //console.log(doc.id,room)

        if(room === undefined){
            console.log('cant find room ',roomId,' reloading')
            allRoomsSynced = false;
            return;
        }

        const msg_send_date = new Date(date.seconds * 1000)
        const last_msg_date = room.lastMessage.createdDate ? new Date(room.lastMessage.createdDate) : null;

        if(room.readAt === null){
            if(last_msg_date && msg_send_date < last_msg_date){
                //jezeli wiadomosc ktora dostalismy ze snapshota ma date wyslania mniejsza niz ostatnia wiadomosc w pokoju
                //to ignorujemy ja pomimo tego ze readAt === null
                return
            }
            console.log('room readAt == null marking unread',roomId, room)
            !room.unread && markUnread(roomId)
            return;
        }
        //todo: logika read / unread sie chrzanila ale teraz jakby sie nie chrzani, sprawdzic np. jak druga osoba napisze to czy nagle wczesniejszy user u ktorego bylo wszystko przeczytane staje sie unread
        const room_read_date = new Date(room.readAt)
        
        if(!room.unread &&  (room_read_date < msg_send_date)){
            console.log('marking unread, room date ',room_read_date,' msg date ',msg_send_date,' from ',room.participant.name, room.participant.surname)
            markUnread(roomId)
            return;
        }

    })
    return allRoomsSynced;
}

export {useRooms}
