import { useLoggedUser } from "../store/useLoggedUser"
import { AccessDenied, UserNotLogged, ServerError } from "./apiErrors"
import { fetchGet } from "./restHelpers"

interface iRoom {
    roomId: string,
    readAt: null | string,
    pinned: boolean,
    unread: boolean,
    newuser?: boolean,
    lastMessage: {
        userId: null | string,
        createdDate: null | string
    },
    participant: {
        name?:string,
        surname?:string,
        account_id?:string,
        role_id?:string,
        registrationDate?: string,
        email?:string,
        phone?:string
    }
}


async function getRoomsList(){
    const endpoint = "/admin/doctor-management/chat-rooms"

    console.log('getRoomList()')
    const {logged,jwt,account_id} = useLoggedUser.getState()
    if(!logged)throw new UserNotLogged()
    
    const result = await fetchGet(endpoint,undefined,jwt)

    if(result?.status == 403)throw new AccessDenied()
    if(!result.count || !result.data) throw new ServerError()

    //return result;

    return transformRoomsList(result.data,account_id)

}



function transformRoomsList(rooms:any, account_id:string):iRoom[]{
    if(!Array.isArray(rooms)) return []

    //rooms.slice(0,3).forEach( r => console.log(r))


    return rooms.map(r => ({
        roomId: r?.room_id || "",
        readAt: r?.read_at || null,
        pinned: r?.is_pinned || false,
        unread: isUnread(account_id,{userId:r?.lastmsg_userid,createdDate:r?.lastmsg_createddate},r?.read_at),
        newuser: howManyHoursAgo(r?.user_registrationdate) < 48,
        lastMessage: {
            userId:r?.lastmsg_userid,
            createdDate:r?.lastmsg_createddate
        },
        participant:{
            name:r?.user_name,
            surname:r?.user_surname,
            account_id:r?.user_id,
            role_id:r?.user_role,
            registrationDate:r?.user_registrationdate,
            email:r?.user_email,
            phone:r?.user_phone
        }
    }))
    
}

function howManyHoursAgo(date:string){
    if(date == "" || date === undefined || date === null) return NaN
    const thenDate = new Date(date)
    if(thenDate.toString() == "Invalid Date")return NaN
    const then = Math.floor(thenDate.getTime() / 1000)
    const now = Math.floor(new Date().getTime() / 1000)
    return Math.floor( (now - then) / 3600 )
}

const compareDates = (a:any,b:any) => {
    if(a == b) return 0
    if(a === null || a === undefined) return -1;
    if(b === null || b === undefined) return 1;
    
    if(new Date(a) < new Date(b)){
        return -1
    }
    return 1
}

//z tym jest problem?bo uzywamy tej funkcji tylko pzy ladowaniu danych, a nie przy wysylce wiadomosci ktora zmienia unread wg. tej samej logiki
const isUnread = (account_id:string, lastMessage:any, readAt:any) => {
    if(!lastMessage || !lastMessage?.createdDate) return false;
    if(lastMessage?.userId == account_id)return false;
    if(lastMessage?.createdDate && !readAt)return true;
    if(new Date(lastMessage?.createdDate) > new Date(readAt))return true;
    return false;
}




/*
{
  "count": 2128,
  "data": [
    {
      "room_id": "559b94b8-e0d8-454d-94a9-552880d82f44",
      "is_pinned": false,
      "read_at": null,
      "lastmsg_createddate": "2023-03-30T12:55:48.260Z",
      "lastmsg_userid": "d956dc8e-be21-4aeb-8481-4e88cfee8b79",
      "user_id": "cc7b185e-1c0a-41e9-b36a-c8ef1e4117be",
      "user_name": "Anna",
      "user_surname": "Sankowska-Dobrowolska",
      "user_role": "patient",
      "user_registrationdate": "2023-03-30T12:55:48.156Z",
      "user_email": "dr.annasd@gmail.com"
    },
*/


async function getRoomsListOld(){
    const endpoint = "/common/chat/rooms-list"

    const {logged,jwt,account_id} = useLoggedUser.getState()
    if(!logged)throw new UserNotLogged()
    
    const result = await fetchGet(endpoint,undefined,jwt)

    if(result?.status == 403)throw new AccessDenied()

    //return result;

    return transformLegacyRoomsList(result,account_id)

}


function transformLegacyRoomsList(rooms:any, account_id:string):iRoom[]{
    if(!Array.isArray(rooms)) return []

    const clearRoomData = (room:any) => ({
        roomId : room?.roomId || "",
        readAt: room?.readAt || null,
        pinned: room?.pinned || false,
        unread: isUnread(account_id,room?.lastMessage,room?.readAt),
        lastMessage: room?.lastMessage || {userId:null,createdDate:null},
        participant: getRoomParticipant(room?.users ?? []) ?? {}  
    })

    const getRoomParticipant = (users:any[]) => users.find(user => user?.account_id != account_id)

    return rooms.map(clearRoomData).sort((a:any,b:any)=>compareDates(b?.lastMessage?.createdDate, a?.lastMessage?.createdDate))

    /*
    {
    "roomId": "4e732665-e2ad-4965-be7a-4e53475cdc56",

    "readAt": null,

    "pinned": false,

    "lastMessage": {

      "userId": "5199b84a-d2f1-4752-9778-951b9bfcbb72",

      "createdDate": "2023-02-27T13:59:46.230Z"

    },

    "users": [

      {

        "name": "Jan",

        "surname": "Kowalski",

        "account_id": "d956dc8e-be21-4aeb-8481-4e88cfee8b79",

        "role_id": "customer_service"

      },...]
    }
    */

}

export {getRoomsList,getRoomsListOld}
export type {iRoom}