
import './App.css';
import React, { useEffect, useRef, useState } from 'react';

import {  login, password } from './config';
import { useLoggedUser } from './store/useLoggedUser';


import {
  createHashRouter,
  RouterProvider,
} from "react-router-dom";
import { LoginForm } from './components/LoginForm';
import ChatApp from './ChatApp';
import { RegisteredDoctorsApp } from './RegisteredDoctorsApp';
import { SendInvitationApp } from './SendInvitationApp';
import { SmOneApp } from './SmOneApp';


const router = createHashRouter([
  {path:'/', element: <ChatApp/>},
  {path:'/doctors', element: <RegisteredDoctorsApp/>},
  {path:'/sendinvitation', element: <SendInvitationApp/>},
  {path:'/smone', element: <SmOneApp/>},
])



function App() {

  const user = useLoggedUser()

  useEffect(()=>{

    ///ZAKOMENTOWAC!
    
    if(!user.logged && window.location.hostname == 'localhost'){
      //user.logUserIn(login,password).then(
        //result => rooms.load() 
      //)
    }
    
    

  },[])

  return !user.logged 
    ? <div className="App"><LoginForm></LoginForm></div>
    : <RouterProvider router={router} ></RouterProvider>
  
}

export {App};
