import { Link, NavLink } from "react-router-dom"
import { useLoggedUser } from "../store/useLoggedUser"

function AppHeader(){
    const user = useLoggedUser()
    
    return <div className='AppHeader'>
        <NavLink to="/">Chat</NavLink> | <NavLink to="/doctors">Registered doctors</NavLink> | <NavLink to="/sendinvitation">Send an invitation</NavLink> | <NavLink to="/smone">SM-One</NavLink> | {user.login}
        </div>
}

export {AppHeader}