import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { ElementRef, useEffect, useMemo, useRef, useState } from "react"
import { AccessDenied, ServerError } from "./api/apiErrors";
import { fetchPost } from "./api/restHelpers";
import { AppHeader } from "./components/AppHeader"


async function postInvitation(data:{type:string, id:string, phone:string}){
    const endpoint = "/doctor/public-profile/invitation"
    const result = await fetchPost(endpoint,data)
    return result;
}


function SendInvitationApp(){
    //const { id: publicId, phone: patientPhoneNumber, type } = data;
    
    const [isSending, setIsSending] = useState(false)
    const formRef = useRef<ElementRef<"form">>(null)


    const handleSubmit = async (ev:any) => {
        const formEls = formRef.current?.elements
        const formdata = {
            "type": (formEls?.namedItem('type') as RadioNodeList).value,
            "id": (formEls?.namedItem("id") as HTMLInputElement).value,
            "phone": (formEls?.namedItem("phone") as HTMLInputElement).value,
        }
        console.log('form button clicked',formdata)
        if(!formdata.type || !formdata.id || !formdata.phone) return;
        ev.preventDefault()

        setIsSending(true)

        const result = await postInvitation(formdata)
        console.log(result)
        setIsSending(false)

        if(result?.status!==201){
            const msg = result?.message
            if(!msg){
                alert('Error while sending an invitation')
                return;
            }
            alert('Error: ' +  msg.toString())
            return;
        }
        alert('Success')
        formRef.current?.reset()
    }


    return <div className="SendInvitationApp">
            <AppHeader/>
            <div className='AppContent'>
            <h2>Send an invitation</h2>
            <form ref={formRef}>
                <div className="row">
                    <label htmlFor="type">Type: </label>
                    <div>
                        <label> Freemium <input type="radio" name="type" value="free" required /></label>
                        <label> Main paid plan <input type="radio" name="type" value="premium" required /></label>
                    </div>
                </div>
                <div className="row"><label htmlFor="id">Doctor's public ID</label> <input type="text" name="id" required></input></div>
                <div className="row"><label htmlFor="phone">Patient's phone number</label> <input type="text" name="phone" required></input></div>
                <div className="row">
                    <button disabled={isSending} onClick={handleSubmit}>{isSending ? "sending.." : "Invite patient"}</button>
                </div>

            </form>
            </div>
            </div>
}


export {SendInvitationApp}